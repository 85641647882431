@import "primereact/resources/themes/lara-light-indigo/theme.css";
@import "primereact/resources/primereact.min.css";
@import "primeicons/primeicons.css";

@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Archivo';
    src: url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
}

html {
    background-color: #0D1216;
}

/*.text-gold {*/
/*    color: #C1A875;*/
/*}*/

.count-down-box {
    background: linear-gradient(0deg, #C1A875, #C1A875), radial-gradient(69.75% 69.75% at 3.37% -3.75%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(70.64deg, #576265 12.9%, #576265 27.97%, #9EA1A1 46.82%, #757A7B 57.44%, #848B8A 74.28%, #576265 83.01%, #576265 92.66%);
    background-blend-mode: color, overlay, normal;
    box-shadow: inset 0px -1px 1.5px rgba(0, 0, 0, 0.5), inset 0px 1px 1.5px rgba(255, 255, 255, 0.4);
    border-radius: 16px;
    @apply flex items-center justify-center font-semibold text-2xl w-16 h-16 md:w-24 md:h-24  md:text-4xl
}

.menu {
    /*width: 280px;*/
    /*height: 0.5px;*/
    /*background: linear-gradient(0deg, #D1A956, #D1A956),*/
    /*radial-gradient(69.75% 69.75% at 3.37% -3.75%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%),*/
    /*linear-gradient(70.64deg, rgba(87, 98, 101, 0) 8.39%, rgba(87, 98, 101, 0.09) 38.46%, #9EA1A1 48.33%, #757A7B 51.49%, #848B8A 61.16%, rgba(87, 98, 101, 0.1) 67.33%, rgba(87, 98, 101, 0) 92.66%);*/
    /*background-blend-mode: color, overlay, normal;*/
    /*border-radius: 8px;*/
}

.gold-button {
    font-family: 'Archivo', sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
    /* Main Gold 2 */
    background: linear-gradient(0deg, #D1A956, #D1A956), radial-gradient(69.75% 69.75% at 3.37% -3.75%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(70.64deg, #576265 12.9%, #576265 27.97%, #9EA1A1 46.82%, #757A7B 57.44%, #848B8A 74.28%, #576265 83.01%, #576265 92.66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /*background-clip: text;*/
    text-fill-color: transparent;
    background-blend-mode: color, overlay, normal;
    @apply px-4 py-2 rounded-full cursor-pointer


}

.gold-button:hover {
    background: linear-gradient(0deg, #D1A956, #D1A956), radial-gradient(69.75% 69.75% at 3.37% -3.75%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(70.64deg, #576265 12.9%, #576265 27.97%, #9EA1A1 46.82%, #757A7B 57.44%, #848B8A 74.28%, #576265 83.01%, #576265 92.66%);
    background-blend-mode: color, overlay, normal;
    box-shadow: inset 0px -1px 1.5px rgba(0, 0, 0, 0.5), inset 0px 1px 1.5px rgba(255, 255, 255, 0.4);
    border-radius: 28px;
    font-family: 'Archivo';
    text-fill-color: black;
    -webkit-text-fill-color: black;
    -webkit-background-clip: padding-box;
    color: #0D1216;
}

.text-reset {
    -webkit-background-clip: padding-box;
    -webkit-text-fill-color: black;
    /*background-clip: text;*/
    text-fill-color: black;
}

.gold-button-border {
    /* display: flex; */
    /* flex-direction: row; */
    /* align-items: flex-start; */
    /*padding: 15px 40px;*/
    /*gap: 8px;*/

    box-shadow: inset 0px -1px 1.5px rgb(241, 158, 95), inset 0px 1px 1.5px rgb(211, 129, 64);
    /*border-radius: 62px;*/
}

.nft-content-bg {
    background-color: #1E1E1E;
}

.content-div {
    /*background-image:url('https://images.unsplash.com/photo-1522093007474-d86e9bf7ba6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=80');*/
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.content-div:hover {
    background-image: linear-gradient(to right,
    rgba(126, 213, 111, 0.801), hsla(160, 64%, 43%, 0.801)),
    url('https://images.unsplash.com/photo-1522093007474-d86e9bf7ba6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=80');
}

.image-cover {
    height: 260px;
}

/*
-remove the classes below if you have the 'group-hover'property added in your tailwind config file
-also remove the class from the html template
*/
.content-div:hover .fd-cl {
    opacity: 0.25;
}

.content-div:hover .fd-sh {
    opacity: 1;
}

.component-shadow {
    background: linear-gradient(176.6deg, rgba(13, 18, 22, 0.36) 2.69%, rgba(12, 16, 19, 0.36) 73.49%, rgba(9, 12, 15, 0.36) 97.13%);
    box-shadow: inset 0px -1px 4px rgba(0, 0, 0, 0.5), inset 0px 2px 4px rgba(255, 255, 255, 0.4);

    /* Note: backdrop-filter has minimal browser support */
    @apply rounded-3xl border border-2 drop-shadow-lg shadow-amber-50
}

.component-shadow-bronze {
    background: linear-gradient(176.6deg, rgba(13, 18, 22, 0.36) 2.69%, rgba(12, 16, 19, 0.36) 73.49%, rgba(98, 38, 30, 0.36) 97.13%);
    box-shadow: inset 0px -1px 4px rgba(61, 1, 1, 0.5), inset 0px 2px 4px rgba(255, 255, 255, 0.4);
}

.component-shadow-silver {
    background: linear-gradient(176.6deg, rgba(13, 18, 22, 0.36) 2.69%, rgba(12, 16, 19, 0.36) 73.49%, rgba(98, 93, 91, 0.36) 97.13%);
    box-shadow: inset 0px -1px 4px rgba(45, 45, 45, 0.5), inset 0px 2px 4px rgba(255, 255, 255, 0.4);
}

.component-shadow-gold {
    background: linear-gradient(176.6deg, rgba(13, 18, 22, 0.36) 2.69%, rgba(12, 16, 19, 0.36) 73.49%, rgba(79, 68, 2, 0.36) 97.13%);
    box-shadow: inset 0px -1px 4px rgba(115, 62, 0, 0.5), inset 0px 2px 4px rgba(255, 255, 255, 0.4);
}

.component-shadow-platinum {
    background: linear-gradient(176.6deg, rgba(13, 18, 22, 0.36) 2.69%, rgba(12, 16, 19, 0.36) 73.49%, rgba(136, 136, 136, 0.16) 97.13%);
    box-shadow: inset 0px -1px 4px rgba(141, 141, 141, 0.25), inset 0px 2px 4px rgba(255, 255, 255, 0.4);
}

.button-gold {
    @apply border-[#C1A875] border-2 text-[#C1A875] rounded-full py-2 px-8 bg-[#0D1216] cursor-pointer
}

.button-gold-normal {
    @apply border-[#C1A875] border-2 text-[#C1A875] rounded-full py-3 px-8 bg-[#0D1216] cursor-pointer hover:border-transparent
}

.button-gold-normal:hover {
    @apply rounded-full  py-3 px-8;
    background: linear-gradient(0deg, #D1A956, #D1A956), radial-gradient(69.75% 69.75% at 3.37% -3.75%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(70.64deg, #576265 12.9%, #576265 27.97%, #9EA1A1 46.82%, #757A7B 57.44%, #848B8A 74.28%, #576265 83.01%, #576265 92.66%);
    background-blend-mode: color, overlay, normal;
    /*box-shadow: inset 0px -1px 1.5px rgba(0, 0, 0, 0.5), inset 0px 1px 1.5px rgba(255, 255, 255, 0.4);*/
    /*border-radius: 28px;*/
    font-family: 'Archivo';
    text-fill-color: black;
    -webkit-text-fill-color: black;
    -webkit-background-clip: content;
    color: #0D1216;
}

.text-gold {
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 500;
    /*font-size: 24px;*/
    /*line-height: 26px;*/
    text-align: center;
    text-transform: uppercase;

    /* Main Gold 2 */
    background: linear-gradient(0deg, #D1A956, #D1A956), radial-gradient(69.75% 69.75% at 3.37% -3.75%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(70.64deg, #576265 12.9%, #576265 27.97%, #9EA1A1 46.82%, #757A7B 57.44%, #848B8A 74.28%, #576265 83.01%, #576265 92.66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    background-blend-mode: color, overlay, normal;
}
.text-gold-disabled{
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 500;
    /*font-size: 24px;*/
    /*line-height: 26px;*/
    text-align: center;
    text-transform: uppercase;

    /* Main Gold 2 */
    background: linear-gradient(0deg, rgba(209, 169, 86, 0.4), rgba(209, 169, 86, 0.52)), radial-gradient(69.75% 69.75% at 3.37% -3.75%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(70.64deg, #576265 12.9%, #576265 27.97%, #9EA1A1 46.82%, #757A7B 57.44%, #848B8A 74.28%, #576265 83.01%, #576265 92.66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    background-blend-mode: color, overlay, normal;
}

.menu-text-gold {
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 200;
    /*font-size: 24px;*/
    /*line-height: 26px;*/
    text-align: center;
    text-transform: uppercase;

    /* Main Gold 2 */
    /*background: linear-gradient(0deg, #D1A956, #D1A956), radial-gradient(69.75% 69.75% at 3.37% -3.75%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%) !* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected *!, linear-gradient(70.64deg, #576265 12.9%, #576265 27.97%, #9EA1A1 46.82%, #757A7B 57.44%, #848B8A 74.28%, #576265 83.01%, #576265 92.66%);*/
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    background-blend-mode: color, overlay, normal;
}

.menu {
    /*@apply hover:underline hover:underline-offset-4 hover:text-opacity-70*/
}

.text-platinum-mint {
    background: linear-gradient(0deg, #a29d94, #969595), radial-gradient(69.75% 69.75% at 3.37% -3.75%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(70.64deg, #576265 12.9%, #576265 27.97%, #9EA1A1 46.82%, #757A7B 57.44%, #848B8A 74.28%, #576265 83.01%, #576265 92.66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    background-blend-mode: color, overlay, normal;
}

.text-gold-mint {
    /*background: linear-gradient(0deg, #DEC60E, #DEC60E), radial-gradient(69.75% 69.75% at 3.37% -3.75%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%) !* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected *!, linear-gradient(70.64deg, #576265 12.9%, #576265 27.97%, #9EA1A1 46.82%, #757A7B 57.44%, #848B8A 74.28%, #576265 83.01%, #576265 92.66%);*/
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    background-blend-mode: color, overlay, normal;
}

.text-silver-mint {
    /* NFT Silver */
    /*background: radial-gradient(69.75% 69.75% at 3.37% -3.75%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%) !* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected *!, linear-gradient(70.64deg, #323637 12.9%, #565859 27.97%, #888D8D 46.82%, #585E60 57.44%, #7C8281 74.28%, #565859 83.01%, #323637 92.66%);*/
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    background-blend-mode: overlay, normal;
}

.text-bronze-mint {
    /*background: linear-gradient(0deg, #D79341, #bd7314), radial-gradient(69.75% 69.75% at 3.37% -3.75%, #9a4343 0%, rgba(255, 255, 255, 0) 100%) !* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected *!, linear-gradient(70.64deg, #576265 12.9%, #576265 27.97%, #9EA1A1 46.82%, #757A7B 57.44%, #848B8A 74.28%, #576265 83.01%, #576265 92.66%);*/
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    background-blend-mode: color, overlay, normal;
}

.shadow-platinum {
    box-shadow: inset 0px -1px 1.5px rgba(0, 0, 0, 0.5), inset 0px 1px 1.5px #a4acb6;
    @apply rounded-full border border-2 border-slate-300 drop-shadow-lg shadow-slate-400
}

.shadow-gold {
    box-shadow: inset 0px -1px 1.5px rgba(101, 97, 97, 0.5), inset 0px 1px 1.5px #d7bc13;
    @apply rounded-full border border-2 border-yellow-300 drop-shadow-lg shadow-yellow-300
}

.shadow-silver {
    box-shadow: inset 0px -1px 1.5px rgba(0, 0, 0, 0.5), inset 0px 1px 1.5px #999999;
    @apply rounded-full border border-2 border-sky-50 drop-shadow-lg shadow-slate-200
}

.shadow-bronze {
    box-shadow: inset 0px -1px 1.5px rgba(0, 0, 0, 0.5), inset 0px 1px 1.5px #bd7314;
    border-color: #bd7314;
    @apply rounded-full border border-2  drop-shadow-lg
}

.menu-text {
    background: linear-gradient(0deg, #C1A875, #C1A875), radial-gradient(69.75% 69.75% at 3.37% -3.75%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(70.64deg, #576265 12.9%, #576265 27.97%, #9EA1A1 46.82%, #757A7B 57.44%, #848B8A 74.28%, #576265 83.01%, #576265 92.66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    background-blend-mode: color, overlay, normal;
}

.menu-active {
    background: linear-gradient(0deg, #f8c563, #c9921f), radial-gradient(69.75% 69.75% at 3.37% -3.75%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(70.64deg, #576265 12.9%, #576265 27.97%, #9EA1A1 46.82%, #757A7B 57.44%, #848B8A 74.28%, #576265 83.01%, #576265 92.66%);
    -webkit-background-clip: text;
    /*-webkit-text-fill-color: transparent;*/
    background-clip: text;
    /*text-fill-color: transparent;*/
    background-blend-mode: color, overlay, normal;

    @apply pb-2 border-b border-[#c9921f] border-opacity-100
}

.menu-text:hover {
    background: linear-gradient(0deg, #f8c563, #c9921f), radial-gradient(69.75% 69.75% at 3.37% -3.75%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(70.64deg, #576265 12.9%, #576265 27.97%, #9EA1A1 46.82%, #757A7B 57.44%, #848B8A 74.28%, #576265 83.01%, #576265 92.66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    background-blend-mode: color, overlay, normal;

    @apply pb-2 border-b border-[#c9921f] border-opacity-50
}

/*.gold-separator {*/
/*    background: linear-gradient(0deg, #D1A956, #D1A956), radial-gradient(69.75% 69.75% at 3.37% -3.75%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%) !* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected *!, linear-gradient(70.64deg, rgba(87, 98, 101, 0) 8.39%, rgba(87, 98, 101, 0.09) 38.46%, #9EA1A1 48.33%, #757A7B 51.49%, #848B8A 61.16%, rgba(87, 98, 101, 0.1) 67.33%, rgba(87, 98, 101, 0) 92.66%);*/
/*    background-blend-mode: color, overlay, normal;*/
/*    border-radius: 8px;*/
/*    height: 0.39px;*/
/*}*/
.gold-separator {
    background: linear-gradient(123deg, #141414, #efaa4e, #272726);
    background-size: 100% 600%;

    -webkit-animation: SepratorMove 12s ease infinite;
    -moz-animation: SepratorMove 12s ease infinite;
    -o-animation: SepratorMove 12s ease infinite;
    animation: SepratorMove 12s ease infinite;
}
.gold-separator-2 {
    background: linear-gradient(123deg, #efaa4e, #efaa4e, #efaa4e);
    background-size: 100% 600%;

    -webkit-animation: SepratorMove 12s ease infinite;
    -moz-animation: SepratorMove 12s ease infinite;
    -o-animation: SepratorMove 12s ease infinite;
    animation: SepratorMove 12s ease infinite;
}

@-webkit-keyframes SepratorMove {
    0% {
        background-position: 44% 0%
    }
    50% {
        background-position: 57% 100%
    }
    100% {
        background-position: 44% 0%
    }
}

@-moz-keyframes SepratorMove {
    0% {
        background-position: 44% 0%
    }
    50% {
        background-position: 57% 100%
    }
    100% {
        background-position: 44% 0%
    }
}

@-o-keyframes SepratorMove {
    0% {
        background-position: 44% 0%
    }
    50% {
        background-position: 57% 100%
    }
    100% {
        background-position: 44% 0%
    }
}

@keyframes SepratorMove {
    0% {
        background-position: 44% 0%
    }
    50% {
        background-position: 57% 100%
    }
    100% {
        background-position: 44% 0%
    }
}

@supports (-webkit-touch-callout: none) {
    .h-screen {
        height: -webkit-fill-available;
    }
}

@media only screen and (max-device-height: 640px) {
    .h-screen {
        height: 90vh;
    }
}